import React, { useState } from 'react';

// Reusable NavLink component
const NavLink = ({ href, children, className }) => (
  <a
    href={href}
    className={`block mt-4 md:mt-0 md:inline-block text-base ${className}`}
  >
    {children}
  </a>
);

// Menu items configuration
const menuItems = [
  {
    href: 'https://web.listan.ir',
    label: 'ورود به برنامه',
    className:
      'outline-none border rounded-full text-center px-6 py-2 bg-[#72dcff] border-[#7bdeff] text-white hover:bg-[#64cdf0] hover:border-[#64cdf0]',
  },
  {
    href: '#download',
    label: 'دریافت از بازار',
    className:
      'bg-gray-200 text-gray-500 cursor-not-allowed outline-none border border-zinc-300 rounded-full text-center px-6 py-2',
  },
  {
    href: 'https://t.me/ListanSupport',
    label: 'تماس با ما',
    className:
      'outline-none border border-zinc-300 rounded-full text-center px-6 py-2 hover:bg-[#72dcff] hover:border-[#7bdeff] hover:text-white',
  },
];
// Menu component for both mobile and desktop
const Menu = ({ isOpen, className }) => (
  <nav className={className} aria-label="Main navigation">
    <ul className="text-zinc-500 md:flex md:gap-4 text-right md:text-zinc-600">
      {menuItems.map((item) => (
        <li key={item.href} className="mt-6 md:mt-0">
          <NavLink href={item.href} className={item.className}>
            {item.label}
          </NavLink>
        </li>
      ))}
    </ul>
  </nav>
);

// Logo component
const Logo = () => (
  <div className="flex items-center">
    <a className="flex items-center cursor-pointer" href="https://listan.ir">
      <img src="/logo512.png" className="h-14 ml-4" alt="Logo" />
      <div className="text-4xl leading-loose">
        <span className="text-[#1b528e]">لـ‌یـ‌سـ‌تــ‌</span>
        <span className="text-[#5ED2FA]">انــــــــــــــــــ‌</span>
      </div>
    </a>
  </div>
);

// Menu button component for mobile
const MenuButton = ({ toggleMenu }) => (
  <button
    onClick={toggleMenu}
    aria-label="Toggle Menu"
    className="block md:hidden items-center px-2 py-2 border rounded-full text-zinc-400 border-zinc-400 outline-none hover:ring-zinc-200 hover:ring-2 focus:ring-2 focus:ring-[#5ED2FA] focus:ring-opacity-50 focus:border-[#5ED2FA]"
  >
    <svg
      className="fill-current h-6 w-6"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 6h16M4 12h16M4 18h16"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  </button>
);

// Navbar component
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header
      className="pt-4 pb-4 pl-8 pr-8 relative z-10"
      role="banner"
    >
      <div className="flex justify-between items-center">
        <Logo />
        <MenuButton toggleMenu={toggleMenu} />
        <Menu className="hidden md:block md:items-center md:w-auto" />
      </div>
      <div
        className={`md:hidden transition-max-height duration-300 ease-in-out overflow-hidden ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className="m-5 border-t border-gray-300">
          <Menu isOpen={isOpen} />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
