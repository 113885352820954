import React from "react";
import PlanImage from "../assets/Plan.png";
import DoneImage from "../assets/Done.png";
import GrowImage from "../assets/Grow.png";

const OfferItem = ({ image, title, description }) => (
  <div className="w-full flex flex-col items-center gap-4" role="article" aria-labelledby={`${title.replace(/\s+/g, '-')}-title`}>
    <img src={image} alt={title} className="w-20" />
    <h3 id={`${title.replace(/\s+/g, '-')}-title`} className="font-semibold text-zinc-600">
      {title}
    </h3>
    <p className="text-center text-zinc-500 w-6/12">{description}</p>
  </div>
);

const OurOffer = () => {
  return (
    <section className="flex flex-col mt-20 items-center gap-6" aria-labelledby="our-offer-title">
      <div className="text-md text-cyan-600">چه‌ کاری انجام می‌دهیم؟</div>
      <h2 id="our-offer-title" className="text-3xl font-semibold text-zinc-600">
        پیشنهاد ما به شما
      </h2>
      <p className="p-5 pt-0 text-wrap text-zinc-500 text-center leading-8 text-base md:w-7/12">
        هدف لیستان این است که فرآیند ثبت و مدیریت کارها و یادداشت‌های روزمره را برای
        کاربران به ساده‌ترین و سریع‌ترین شکل ممکن فراهم کند، به‌طوری که بتوانند با استفاده از تقویم جلالی و رابط کاربری ساده و دلنشین، 
        به‌راحتی و بدون دغدغه تمامی کارها و یادداشت‌های خود را در دستگاه‌های مختلف سازماندهی و مدیریت کنند.
      </p>
      <div className="flex flex-col gap-16 lg:flex-row lg:gap-0">
        <OfferItem
          image={PlanImage}
          title="برنامه‌ریزی و یادداشت کن"
          description="با لیستان، هم کارهای خود را برنامه‌ریزی کنید و هم ایده‌ها و یادداشت‌های خود را به بهترین شکل سازماندهی کنید."
        />
        <OfferItem
          image={DoneImage}
          title="مدیریت و انجامش بده"
          description="هر کار و یادداشتی که با لیستان ثبت می‌کنید، به شما کمک می‌کند تا بهتر مدیریت کرده و به هدف‌هایتان نزدیک‌تر شوید."
        />
        <OfferItem
          image={GrowImage}
          title="رشد کن"
          description="با لیستان، نه تنها کارها و یادداشت‌های روزانه‌تان را مدیریت کنید، بلکه با هر کار و یادداشتی که به انجام می‌رسانید، مسیر رشد و پیشرفت خود را هموار کنید."
        />
      </div>
    </section>
  );
};

export default OurOffer;
