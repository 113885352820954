import React, { useState, useEffect } from 'react';

const SupportButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.body.offsetHeight;
            // Check if user has scrolled to the top or bottom
            const isTop = scrollTop <= 50;
            const isBottom = scrollTop + windowHeight >= documentHeight - 50;
            setIsVisible(!(isTop || isBottom));
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        isVisible && (
            <div className={`fixed bg-[#72dcff] border-[#7bdeff] text-white hover:bg-[#64cdf0] hover:border-[#64cdf0] bottom-4 left-4 font-bold py-1 px-2 rounded-full shadow-lg transition-opacity duration-200 ease-in-out ${isVisible ? 'opacity-50 hover:opacity-100' : 'opacity-0 pointer-events-none'
                }`}>
                <a className="text-sm flex gap-2 items-center" href="https://t.me/ListanSupport" target="_blank" rel="noopener noreferrer" aria-label="پشتیبانی">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                    </svg>
                    <div>پشتیبانی<sup className='absolute rounded-full w-4 h-4 bg-green-400 animate-ping' /></div>
                </a>
            </div>
        ));
};

export default SupportButton;
