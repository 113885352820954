import React from 'react';
import jalaali from 'jalaali-js';

const Footer = () => {
  const today = new Date();
  const jalaliDate = jalaali.toJalaali(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );

  // Function to convert numbers to Farsi digits
  const convertToFarsiDigits = (num) => {
    return num.toString().replace(/\d/g, (digit) => '۰۱۲۳۴۵۶۷۸۹'[digit]);
  };

  const jalaliYearFarsi = convertToFarsiDigits(jalaliDate.jy);

  return (
    <footer
      id="footer"
      className="border-t-2 w-11/12 flex flex-col items-center gap-2 py-4 px-1 mx-auto mt-28 text-sm text-gray-600"
      role="contentinfo"
    >
      <p>طراحی و توسعه با ❤️ توسط تیم لیستان</p>
      <p>کپی‌رایت © {jalaliYearFarsi} - تمامی حقوق این وب‌سایت محفوظ است.</p>
    </footer>
  );
};

export default Footer;
