import Feature from "./components/Feature";
import Footer from "./components/Footer";
import Intro from "./components/Intro";
import Navbar from "./components/Navbar";
import OurOffer from "./components/OurOffer";
import SupportButton from "./components/Support";

function App() {

  return (
    <>
      <Navbar />
      <Intro />
      <OurOffer />
      <Feature />
      <SupportButton />
      <Footer />
    </>
  );
}

export default App;
